import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  parameters: [
    "voltage",
    "soc",
    "AvgCurrent",
    "AvgAbsCurrent",
    "distance",
    "PeakCurrent",
    "AvgSpeed",
    "AvgAbsSpeed",
    "PeakSpeed",
    "MotorTemperaturePeak",
    "ControllerTemperaturePeak",
    "BatteryTemperaturePeak",
    "iMobChargePercentage",
  ],
};
// "iMotorTemp",
// "iBatteryTemp",
// "iControllerTemp",
const paramSlice = createSlice({
  name: 'param',
  initialState,
  reducers: {
    setParameters: (state, action) => {
      state.parameters = action.payload;
    },
  },
});

export const { setParameters } = paramSlice.actions;
export default paramSlice.reducer;
